import axios from '../plugin/axios';

const commission = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Commission/GetCommission')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    // create(data) {
    //     return new Promise((resolve, reject) => {
    //         axios.setToken();
    //         axios
    //             .post('roles', data)
    //             .then(({ data }) => {
    //                 resolve(data);
    //             })
    //             .catch(({ response }) => {
    //                 reject(response);
    //             });
    //     });
    // },
    get(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Commission/GetCommissionById', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id,data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Commission/UpdateCommission',id,data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    // delete(id) {
    //     return new Promise((resolve, reject) => {
    //         axios.setToken();
    //         axios
    //             .delete('roles', id)
    //             .then(({ data }) => {
    //                 resolve(data);
    //             })
    //             .catch(({ response }) => {
    //                 reject(response);
    //             });
    //     });
    // },
};
export default commission;
