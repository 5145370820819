import axios from '../plugin/axios';

const city = {
    list(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`City/GetCities?Language=${data}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('City/SaveCity', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`City/GetCityById/${data.id}?Language=${data.Language}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    getCityCountry(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`City/GetCityByCountryId?CountryId=${data.id}&Language=${data.Language}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('City/UpdateCity', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('City/DeleteCity', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    UpdateCityStatus(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('City/UpdateCityStatus', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};
export default city;
