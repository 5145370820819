import axios from '../plugin/axios';

const help = {
    list(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Help/GetHelp?Language=${data}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('Help/SaveHelp', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Help/GetHelpById/${data.id}?Language=${data.Language}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Help/UpdateHelp', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Help/DeleteHelp', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    UpdateHelpStatus(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Help/UpdateHelpStatus', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};
export default help;
