import axios from '../plugin/axios';

const productType = {
    list(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`ProductCategory/GetProductCategory?Language=${data}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('ProductCategory/SaveProductCategory', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`ProductCategory/GetProductCategoryById/${data.id}?Language=${data.Language}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            console.log(
                'datadata' , data
            );
            axios.setToken();
            axios
                .update('ProductCategory/UpdateProductCategory', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    UpdateProductCategoryStatus(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('ProductCategory/UpdateProductCategoryStatus', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('ProductCategory/DeleteProductCategory', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};
export default productType;
