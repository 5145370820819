const error = {
    confirmation_password: 'La confirmation du mot de passe ne correspond pas.',
    validators_password: 'Le :attribute doit être un valide.',
    404: '404 Non trouvé',
    405: '405 Méthode Non Autorisée',
    500: '500 Erreur de serveur interne',
    error_message: 'Cette page a peut-être été déplacée ou supprimée. Assurez-vous de vérifier votre orthographe.',
    back_to_home: 'De retour à la maison',
    access_message: "Seul l'administrateur peut accéder à cette page.Merci.."
};
export default error;