import axios from '../plugin/axios';
const baskets = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Bucket/GetBuckets')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('Bucket/SaveBucket', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Bucket/GetBucketById/${data.id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    // getstore(data) {
    //     return new Promise((resolve, reject) => {
    //         axios.setToken();
    //         axios
    //             .get(`Bucket/GetBucketByStoreId/${data.id}`)
    //             .then(({ data }) => {
    //                 resolve(data);
    //             })
    //             .catch(({ response }) => {
    //                 reject(response);
    //             });
    //     });
    // },

    getstore(data) {
        return new Promise((resolve, reject) => {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            axios.setToken();
            axios
                .get(`Bucket/GetBucketByStoreId/${data.id}?Timezone=${timeZone}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Bucket/UpdateBucket', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Bucket/DeleteBucket', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};
export default baskets;
