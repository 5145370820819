import React from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { Lazy } from '../components/Loader';
import Home from '../views/Home';
import App from '../components/layout/App';
import Login from '../components/layout/Login'

export default function Router() {
	return useRoutes([
		{
			path: '/',
			element: <App />,
			children: [
				{ path: '/', element: <Home /> },
				{ path: '/profile', element: <Lazy view={'Account/Profile'} /> },
				{ path: '/change-password', element: <Lazy view={'Account/ChangePassword'} /> },
				{
					path: '/commission',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/Commission/Index'} /> },
						{ path: 'create', element: <Lazy view={'Masters/Commission/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Masters/Commission/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Masters/Commission/View'} /> },
					]
				},
				{
					path: '/categories',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/Categories/Index'} /> },
						{ path: 'create', element: <Lazy view={'Masters/Categories/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Masters/Categories/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Masters/Categories/View'} /> },
					]
				},
				{
					path: '/producttype',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/ProductType/Index'} /> },
						{ path: 'create', element: <Lazy view={'Masters/ProductType/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Masters/ProductType/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Masters/ProductType/View'} /> },
					]
				},
				{
					path: '/uploadexcel',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/UploadExcel/Index'} /> },
						{ path: 'create', element: <Lazy view={'Masters/UploadExcel/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Masters/UploadExcel/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Masters/UploadExcel/View'} /> },
					]
				},
				{
					path: '/country',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/Country/Index'} /> },
						{ path: 'create', element: <Lazy view={'Masters/Country/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Masters/Country/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Masters/Country/View'} /> },
					]
				},
				{
					path: '/city',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/City/Index'} /> },
						{ path: 'create', element: <Lazy view={'Masters/City/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Masters/City/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Masters/City/View'} /> },
					]
				},
				{
					path: '/zipcode',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/Zipcode/Index'} /> },
						{ path: 'create', element: <Lazy view={'Masters/Zipcode/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Masters/Zipcode/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Masters/Zipcode/View'} /> },
					]
				},
				{
					path: '/helpcategory',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/HelpCategories/Index'} /> },
						{ path: 'create', element: <Lazy view={'Masters/HelpCategories/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Masters/HelpCategories/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Masters/HelpCategories/View'} /> },
					]
				},
				{
					path: '/promocodes',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/PromoCodes/Index'} /> },
						{ path: 'create', element: <Lazy view={'Masters/PromoCodes/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Masters/PromoCodes/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Masters/PromoCodes/View'} /> },
					]
				},
				{
					path: '/notification',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Masters/Notification/Create'} /> },
						]
				},
				{
					path: '/stores',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Stores/Index'} /> },
						{ path: 'create', element: <Lazy view={'Stores/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Stores/Create'} /> },
						{ path: ':id/:type/view', element: <Lazy view={'Stores/View'} /> },
						{ path: ':id/:type/bucketsView', element: <Lazy view={'Stores/View'} /> },
					]
				},
				{
					path: '/customers',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Customers/Index'} /> },
						{ path: 'create', element: <Lazy view={'Customers/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Customers/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Customers/View'} /> },
					]
				},
				{
					path: '/paymentsettlement',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'PaymentSettlement/Index'} /> },
						{ path: 'create', element: <Lazy view={'PaymentSettlement/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'PaymentSettlement/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'PaymentSettlement/View'} /> },
						{ path: ':orderId/view', element: <Lazy view={'PaymentSettlement/View'} /> },
					]
				},
				{
					path: '/orders',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Orders/Index'} /> },
						{ path: 'create', element: <Lazy view={'Orders/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Orders/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Orders/View'} /> },
						{ path: ':id/:type/view', element: <Lazy view={'Orders/View'} /> },
					]
				},
				{
					path: '/help',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Help/Index'} /> },
						{ path: 'create', element: <Lazy view={'Help/Create'} /> },
						{ path: ':id/edit', element: <Lazy view={'Help/Create'} /> },
						{ path: ':id/view', element: <Lazy view={'Help/View'} /> },
					]
				},
			]
		},
		{
			path: '/',
			element: <Login />,
			children: [
				{ path: '/login', element: <Lazy view={'Auth/Login'} /> },
				{ path: '/forgot-password', element: <Lazy view={'Auth/ForgotPassword'} /> },
				{ path: '/password-reset/:token', element: <Lazy view={'Auth/PasswordReset'} /> },
				{ path: '/verify', element: <Lazy view={'Auth/EmailVerify'} /> },
				{ path: '/ResetSuccess', element: <Lazy view={'Auth/ResetSuccess'} /> },
				{ path: '/verify/:token', element: <Lazy view={'Auth/VerifyEmailPage'} /> },
			]
		},
		{ path: 'error', element: <Lazy view={'Error'} /> },
		{ path: '*', element: <Navigate to='/error' replace /> },
	]);
}