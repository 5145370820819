import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Link as RouterLink,
    useLocation,
} from 'react-router-dom';
import {
    Typography,
    Breadcrumbs,
    Link,
    Grid
} from '@mui/material';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function BasicBreadcrumbs() {
    const { t } = useTranslation();
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    if (pathnames.length === 0) {
        return null;
    }

    return (
        <Grid container justifyContent='flex-end'>
            <Breadcrumbs className='breadcrumb'>
                <LinkRouter underline='hover' color='inherit' to='/'>
                    {t('menu.home')}
                </LinkRouter>
                {pathnames.map((value, index) => {
                    if (!isNaN(+value)) {
                        return null;
                    }
                    const name = value.replace('-', '_');
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    return last ? (
                        <Typography color='text.primary' key={to}>
                            {t('menu.' + name)}
                        </Typography>
                    ) : (
                        <LinkRouter underline='hover' color='inherit' to={to} key={to}>
                            {t('menu.' + name)}
                        </LinkRouter>
                    );
                })}
            </Breadcrumbs>
        </Grid>
    );
}