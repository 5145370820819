import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

export default function ConfirmDialog({ title, children, open, setOpen, onConfirm }) {
    const { t } = useTranslation();
    return (
        <div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='error' variant='outlined' onClick={() => setOpen(false)}>
                        {t('disagree')}
                    </Button>
                    <Button color='primary' variant='outlined' onClick={onConfirm} autoFocus>
                        {t('agree')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}