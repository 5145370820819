import axios from "../plugin/axios";

const helpCategory = {
  list(data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`HelpCategory/GetHelpCategory?Language=${data}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  create(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("HelpCategory/SaveHelpCategory", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  get(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`HelpCategory/GetHelpCategoryById/${id}?Language=EN`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  update(id, data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update("HelpCategory/UpdateHelpCategory", id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .delete("HelpCategory/DeleteHelpCategory", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  UpdateHelpCategoryStatus(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update("HelpCategory/UpdateHelpCategoryStatus", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
export default helpCategory;
