import axios from "../plugin/axios";

const orders = {
  list(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Order/GetOrders?Language=${data}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  create(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("roles", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  get(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Order/GetOrderById/${data.id}?Language=${data.Language}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  getOrderByStore(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Order/GetOrderByStore?Language=${data}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  getOrderByCustomer(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Order/GetOrderByCustomer?Language=${data}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  update(id, data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update("roles", id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .delete("roles", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  UpdateOrderStatus(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update("Order/UpdateOrderStatus", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
export default orders;
