import React, { useState, useEffect } from "react";
import { auth } from "../models";
import { useNavigate } from "react-router-dom";

export default function withAutoLogout(WrappedComponent) {
  return function AutoLogoutWrapper(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const inactivityTimeout = 5 * 60 * 1000;
    const navigate = useNavigate();

    let timeoutId;

    const resetTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(handleLogout, inactivityTimeout);
    };

    const handleLogout = () => {
      auth
        .logout()
        .then(() => {
          setIsLoggedIn(false);
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          navigate("/login");
        })
        .catch(() => {
          setIsLoggedIn(false);
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          navigate("/login");
        });
    };

    useEffect(() => {
      if (isLoggedIn) {
        window.addEventListener("mousemove", resetTimeout);
        window.addEventListener("keydown", resetTimeout);

        resetTimeout();

        return () => {
          window.removeEventListener("mousemove", resetTimeout);
          window.removeEventListener("keydown", resetTimeout);
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }
    }, [isLoggedIn]);

    return <WrappedComponent isLoggedIn={isLoggedIn} {...props} />;
  };
}
