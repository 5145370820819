import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import {
  StoreMallDirectory as StoreMallDirectoryIcon,
  Shop as ShopIcon,
  People as PeopleIcon,
  CreditCard as CreditCardIcon,
  EuroSymbol as EuroSymbolIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { stores, orders, customers } from "../models";
import notistack from "../plugin/notistack";

const styles = {
  color: "white",
  fontSize: "50px",
};

export default function Home() {
  const { t } = useTranslation();
  const [storeData, setStoreData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [customerData, setCustomerDataData] = useState([]);
  // const [revenueData, setRevenueData] = useState([]);
  const navigate = useNavigate();
  const data = [
    {
      text: "Stores",
      icon: <StoreMallDirectoryIcon sx={styles} />,
      color: "#E91E63",
      count: storeData.length,
      routeLink: "/stores",
    },
    {
      text: "Orders",
      icon: <ShopIcon sx={styles} />,
      color: "#00BCD4",
      count: orderData.length,
      routeLink: "/orders",
    },
    {
      text: "Customers",
      icon: <PeopleIcon sx={styles} />,
      color: "#8BC34A",
      count: customerData.length,
      routeLink: "/customers",
    },
    {
      text: "Total Revenue",
      icon: <CreditCardIcon sx={styles} />,
      color: "#FF9800",
      count: orderData
        ?.filter(
          (item) =>
            item.PaymentStatus === "SUCCEEDED" && item.Delivered === "Completed"
        )
        .reduce((a, b) => a + b.Total, 0)
        .toFixed(2),
      routeLink: "/paymentsettlement",
    },
  ];
  useEffect(() => {
    getStoreData();
    getOrdeData();
    getCustomerData();
  }, []);

  const getStoreData = () => {
    stores
      .list("EN")
      .then((data) => {
        setStoreData(data);
      })
      .catch((error) => {
        notistack.error("Somthing went wrong please try after some time.");
      });
  };

  const getOrdeData = () => {
    orders
      .list("EN")
      .then((data) => {
        setOrderData(data);
      })
      .catch((error) => {
        notistack.error("Somthing went wrong please try after some time.");
      });
  };

  const getCustomerData = () => {
    customers
      .list("EN")
      .then((data) => {
        setCustomerDataData(data);
      })
      .catch((error) => {
        console.log(error?.status === 401);
        if(error?.status === 401){
             navigate('/login')
        }
        notistack.error("Somthing went wrong please try after some time.");
      });
  };

  return (
    <Card>
      <CardHeader title={t("menu.dashboard")} />
      <CardContent>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {data.map((item, index) => (
            <Grid
              container
              sx={{
                bgcolor: item.color,
                color: "white",
                margin: "0% 1% 0% 1%",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                ":hover": {
                  cursor: "pointer",
                },
              }}
              key={index}
            >
              <Grid
                item
                sm={5}
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                  textAlign: "center",
                  padding: "10px 5px 10px 5px",
                }}
                onClick={() => navigate(item.routeLink)}
              >
                <IconButton onClick={() => navigate(item.routeLink)}>
                  {item.icon}
                </IconButton>
              </Grid>
              <Grid
                item
                sm={7}
                sx={{ padding: "10px" }}
                onClick={() => navigate(item.routeLink)}
              >
                <Typography>{item.text}</Typography>
                <Typography fontSize="26px">
                  {index === 3 && <EuroSymbolIcon />}
                  {item.count ? item.count : "000.00"}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
