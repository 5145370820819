const auth = {
  email: "Email address",
  password: "Password",
  old_password: "Old Password",
  confirm_password: "Confirmation Password",
  login: "Login",
  remember_me: "Remember me",
  login_forgot_password: "Forgot password?",
  forgot_password: "Forgot Password",
  already_have_an_account: "Already have an account?",
  password_reset: "Password Reset",
  Password_Reset_Email:"Password Reset Email",
  Do_you_really_want_to_send_an_email:"Do you really want to send an email to all the users for a password reset?",
  cancel: 'Cancel',
  Send:"Send",
  email_verify_message:
    "Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.",
  resend_verification_email: "Resend Verification Email",
  password_changed_successfully: "Password Changed Successfully",
  password_change_error: "Password change failed. Please try again.",
  profile_updated_successfully: "Profile Updated Successfully",
  profile_update_error: "Profile update failed. Please try again.",
  reset_link_is_sent_to_your_email: "Reset link is sent to your email",
  customer_create_error: "Customer not created. Please try again.",
  store_create_error: "Store not created. Please try again.",
  customer_delete_error: "Customer not deleted. Please try again.",
  help_update_error: "Help not updated. Please try again.",
  help_create_error: "Help not created. Please try again.",
  help_status_update_error:
    "Something went wrong. Status not updated. Please try again.",
  help_delete_error:
    "Something went wrong. Help not deleted. Please try again.",
  store_delete_error:
    "Something went wrong. Store not deleted. Please try again.",
  customer_delete_error:
    "Something went wrong. While deleting. Please try again.",
  categorie_update_error:
    "Something went wrong while updating categorie. Please try again.",
  categorie_save_error:
    "Something went wrong while saving categorie. Please try again.",
    city_update_error: "City not updated. Please try again.",
  city_create_error: "City not created. Please try again.",
  while_updating:"Something went wrong. While updating. Please try again.",
  while_saving:"Something went wrong. While saving. Please try again.",
  Go_to_Application:"Go to Application",
  Password_Changed:"Password Changed!",
  your_password_has_been_changed_successfully:"your password has been changed successfully."
 };
export default auth;
