const auth = {
  email: "Adresse e-mail",
  password: "Mot de passe",
  old_password: "ancien mot de passe",
  confirm_password: "Mot de passe de confirmation",
  login: "Connexion",
  remember_me: "Souviens-toi de moi",
  login_forgot_password: "Mot de passe oublié?",
  forgot_password: "Mot de passe oublié",
  already_have_an_account: "Vous avez déjà un compte?",
  password_reset: "Réinitialisation du mot de passe",
  Password_Reset_Email:"E-mail de réinitialisation du mot de passe",
  Do_you_really_want_to_send_an_email:"Voulez-vous vraiment envoyer un e-mail à tous les utilisateurs pour une réinitialisation de mot de passe?",
  cancel: 'Annuler',
  Send:"Envoyer",
  email_verify_message:
    "Merci pour votre inscription! Avant de commencer, pourriez-vous vérifier votre adresse e-mail en cliquant sur le lien que nous venons de vous envoyer ? Si vous n'avez pas reçu l'e-mail, nous vous en enverrons un autre avec plaisir.",
  resend_verification_email: "Renvoyer l'e-mail de vérification",
  password_changed_successfully: "Le mot de passe a été changé avec succès",
  password_change_error:
    "La modification du mot de passe a échoué. Veuillez réessayer.",
  profile_updated_successfully: "Mise à jour du profil réussie",
  profile_update_error:
    "La mise à jour du profil a échoué. Veuillez réessayer.",
  reset_link_is_sent_to_your_email:
    "Le lien de réinitialisation est envoyé à votre adresse e-mail",
  customer_create_error: "Client non créé. Veuillez réessayer.",
  store_create_error: "Magasin non créé. Veuillez réessayer.",
  customer_delete_error: "Client non supprimé. Veuillez réessayer.",
  help_update_error: "Aide non mise à jour. Veuillez réessayer.",
  help_create_error: "Aide non créée. Veuillez réessayer.",
  help_status_update_error:
    "Quelque chose s'est mal passé. Statut non mis à jour. Veuillez réessayer.",
  help_delete_error:
    "Quelque chose s'est mal passé. Aide non supprimée. Veuillez réessayer.",
    customer_delete_error:
    "Quelque chose s'est mal passé. Lors de la suppression. Veuillez réessayer..",
  store_delete_error:
    "Quelque chose s'est mal passé. Magasin non supprimé. Veuillez réessayer.",
  categorie_update_error:
    "Une erreur s'est produite lors de la mise à jour de la catégorie. Veuillez réessayer.",
  categorie_save_error:
  "Une erreur s'est produite lors de l'enregistrement de la catégorie. Veuillez réessayer",
  city_update_error: "Ville non mise à jour. Veuillez réessayer.",
  city_create_error: "Ville non créée. Veuillez réessayer.",
  while_updating:"Quelque chose s'est mal passé. Lors de la mise à jour. Veuillez réessayer.",
  while_saving:"Quelque chose s'est mal passé. Tout en économisant. Veuillez réessayer.",
  Go_to_Application:"Retourner à l’application",
  Password_Changed:"Mot de passe changé!",
  your_password_has_been_changed_successfully:"Votre mot de passe a été changé avec succès."
};
export default auth;
