import axios from '../plugin/axios';

const promocodes = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('PromoCodes/GetPromoCodes')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('PromoCodes/SavePromoCode', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('PromoCodes/GetPromoCodeById', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('PromoCodes/UpdatePromoCode', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('PromoCodes/DeletePromoCode', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    UpdatePromoCodeStatus(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('PromoCodes/UpdatePromoCodeStatus', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    // VerifyPromoCode(data) {
    //     return new Promise((resolve, reject) => {
    //         axios.setToken();
    //         axios
    //             .post(`PromoCodes/VerifyPromoCode/PromoCode?PromoCode=${data.PromoCode}&Total=${data.Total}`,)
    //             .then(({ data }) => {
    //                 resolve(data);
    //             })
    //             .catch(({ response }) => {
    //                 reject(response);
    //             });
    //     });
    // },
};
export default promocodes;
