const menu = {
  home: "Home",
  dashboard: "Dashboard",
  masters: "Masters",
  categories: "Categories",
  buckets: "Buckets",
  promocodes: "Promo Codes",
  act2win: "Act2Win",
  customer_management: "Customer Management",
  order_management: "Order Management",
  store_management: "Store Management",
  payment_settlement: "Payment Settlement",
  help_section: "Help Section",
  commission: "Commission",
  product_type: "Product Type",
  upload_excel: "Upload Excel",
  zipcode: "Zip Code",
  help_category: "Help Category",
  notification:"Notification",
  producttype: "Product Type",
  uploadexcel: "Upload Excel",
  helpcategory: "Help Category",
  paymentsettlement: "Payment Settlement",
  help: "Help",
  Send:"Send",
  pages: "Pages",
  update_page: "Update Page",
  resend_verification:"Resend Verification",
  customers: "Customers",
  new_customer: "New Customer",
  update_customer: "Update Customer",
  customer_details: "Customer Details",
  Reset_Password:"Reset Password",
  orders: "Orders",
  new_order: "New Order",
  update_order: "Update Order",
  order_details: "Order Details",
  order: "Order",

  stores: "Stores",
  new_store: "New Store",
  update_store: "Update Store",
  store_details: "Store Details",
  select_store: "Select Store",

  search: "Search..",
  messages: "Messages",
  notifications: "Notifications",
  profile: "Profile",
  change_password: "Change password",
  logout: "Logout",

  create: "Create",
  edit: "Edit",
  delete: "Delete",
  view: "View",
  convert: "Convert",
  send_email: "Send Email",
  bucketsView: "BucketsView",

  contact: "Contact",
  newsLetter: "News Letter",
  events: "Events",
  engagement: "Engagement",
  donation: "Donation",

  update_categories: "Update Categories",
  new_categories: "New Categories",
  cancel: "Cancel",
  save: "Save",
  update_product_type: "Update product type",
  new_product_type: "New product type",

  update_upload_excel: "Update upload excel",
  upload_excel: "Upload Excel",

  update_country: "Update Country",
  new_country: "New Country",
  country: "Country",

  update_city: "Update City",
  new_city: "New City",
  city: "City",
  update_zip_code: "Update Zipcode",
  new_zip_code: "New Zipcode",
  zip_code: "Zipcode",

  update_help_categories: "Update help categories",
  new_help_categories: "New help categories",
  verify_email: "Verify Email",

  update_store: "Update Store",
  new_store: "New Store",

  update_help_section: "Update help section",
  new_help_section: "New help section",

  upload_excel_for_country_city_and_zipcode:
    "Upload Excel for Country City and Zipcode",

  new_promocode: "New Promo Code",
  update_promocode: "Update Promo Code",
  new_promocode: "New Promo Code",

  promocode_details: "Promo Code Details",
  verify_promocode: "Verify Promocode",

  update_commissions: "Update Commission",
  orders_details: "Orders Details",

  bucket_details: "Bucket Details",
  show: "Stores",
  bucket: "Bucket",

  PaymentSettlement: "Payment Settlement",

  bucket_list: "Bucket List",
};
export default menu;
