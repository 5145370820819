import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
    Avatar,
    CssBaseline,
    Box,
    Container
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    LockOutlined as LockOutlinedIcon
} from '@mui/icons-material';
import { auth } from '../../models';
import { useEffectOnce } from '../../plugin/helper'

export default function LoginLayout() {
    const location = useLocation();
    const theme = createTheme();
    const navigate = useNavigate();

    useEffectOnce(() => {
        checkUser()
    });

    const checkUser = () => {
        if (auth.getToken()) {
            if (!(location.pathname).includes('verify')) {
                navigate('/');
            }
        }
    };
    return (
        <ThemeProvider theme={theme}>
            <div className='main'>
                <Container component='main' maxWidth='xs'>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8
                        }}
                        // sx={{ backgroundColor:"darkgray"}}
                    >
                        {/* <div style={{
                            marginBottom: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                        </div> */}
                        <Outlet/>
                    </Box>
                </Container>
            </div>
        </ThemeProvider>
    );
}