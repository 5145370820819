import axios from "../plugin/axios";

const stores = {
  list(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Store/GetStores?Language=${data}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  create(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("Store/CreateStore", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  get(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Store/GetStoreById/${data.id}?Language=${data.Language}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  update(id, data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update("Store/UpdateStore", id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .delete(`Store/DeleteStore/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  UpdateStoreApprovalStatus(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update(`Store/UpdateStoreApprovalStatus`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  UpdateStoreStatus(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update(`Store/UpdateStoreStatus`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
export default stores;
