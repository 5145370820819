import {
    Home as HomeIcon,
    Settings as SettingsIcon,
    Shop as ShopIcon,
    People as PeopleIcon,
    StoreMallDirectory as StoreMallDirectoryIcon,
    Payment as PaymentIcon,
    Help as HelpIcon,
} from '@mui/icons-material';

const menuItems = [
    { id: 0, label: 'menu.dashboard', module: 'dashboard', link: '/', icon: <HomeIcon color='primary' /> },
    {
        id: 1,
        label: 'menu.masters',
        icon: <SettingsIcon color='secondary' />,
        children: [
            { id: 8, label: 'menu.commission', module:'contacts',  link: '/commission' },
            { id: 9, label: 'menu.categories', link: '/categories' },
            { id: 10, label: 'menu.product_type', module:'accounts',  link: '/producttype' },
            { id: 11, label: 'menu.upload_excel', module:'deals',  link: '/uploadexcel' },
            { id: 12, label: 'attribute.country', module:'deals',  link: '/country' },
            { id: 13, label: 'attribute.city', module:'deals',  link: '/city' },
            { id: 14, label: 'menu.zipcode', module:'deals',  link: '/zipcode' },
            { id: 15, label: 'menu.help_category', module:'deals',  link: '/helpcategory' },
            { id: 16, label: 'menu.promocodes', module:'promocodes',  link: '/promocodes' },
            { id: 17, label: 'Notification', module:'notification',  link: '/notification' },
        ],
    },
    { id: 3, label: 'menu.customer_management', module: 'customers', icon: <PeopleIcon color='success' />, link: '/customers' },
    { id: 4, label: 'menu.order_management', module: 'orders', icon: <ShopIcon color='success' />, link: '/orders' },
    { id: 5, label: 'menu.store_management', module: 'stores', icon: <StoreMallDirectoryIcon color='success' />, link: '/stores' },
    { id: 6, label: 'menu.payment_settlement', module: 'paymentsettlement', icon: <PaymentIcon color='success' />, link: '/paymentsettlement' },
    { id: 7, label: 'menu.help_section', module: 'help', icon: <HelpIcon color='success' />, link: '/help' },
];

export default menuItems;