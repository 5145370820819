import { useTranslation } from "react-i18next";
import axios from "../plugin/axios";
import notistack from "../plugin/notistack";
const ID_TOKEN_KEY = "tokens";

const auth = {
  login(data) {
    return new Promise((resolve, reject) => {
      axios.setLanguage();
      axios
        .post("Authentication/Login", data)
        .then(({ data }) => {
          if (data.Role === "Admin") {
            this.saveToken(data.Token);
            resolve(data);
          } else {
            reject("AccessError");
          }
        })
        .catch(({ response }) => {
          notistack.error(response.data.message);
          reject(response);
        });
    });
  },
  getUser(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Authentication/CustomerDetails?Language=${data}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.destroyToken();
          reject(response);
        });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("Authentication/LogOut")
        .then(({ data }) => {
          notistack.success(data.message);
          this.destroyToken();
          resolve(data);
        })
        .catch(({ response }) => {
          this.destroyToken();
          reject(response);
        });
    });
  },
  forgotPassword(data) {
    return new Promise((resolve, reject) => {
      axios.setLanguage();
      axios
        // .post('auths/forgotPassword', data)
        .post("Authentication/ForgotPassword", {email:data})
        .then(({ data }) => {
          notistack.success(data.message);
          resolve(data);
        })
        .catch(({ response }) => {
          notistack.error(response.data.message);
          reject(response);
        });
    });
  },
  resetPassword(data) {
    return new Promise((resolve, reject) => {
      axios.setLanguage();
      axios
        // Authentication/ResetPassword
        // .post('auths/resetPassword', data)
        .post("Authentication/ResetPassword", data)
        .then(({ data }) => {
          notistack.success(data.message);
          resolve(data);
        })
        .catch(({ response }) => {
          notistack.error(response.data.message);
          reject(response);
        });
    });
  },
  resend() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("resend")
        .then(({ data }) => {
          notistack.success(data.message);
          resolve(data);
        })
        .catch(({ response }) => {
          notistack.error(response.data.message);
          reject(response);
        });
    });
  },
  verify(data) {
    return new Promise((resolve, reject) => {
      axios
        // Authentication/ResendEmail?Email=admin%40gmail.com
        .post(`Authentication/ResendEmail?Email=${data}`)
        // .post('auths/emailVerify', data)
        .then(({ data }) => {
          notistack.success(data.message);
          resolve(data);
        })
        .catch(({ response }) => {
          notistack.error(response.data.message);
          reject(response);
        });
    });
  },
  verifyEmail(token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`Authentication/VerifyCustomerEmail?Token=${token}`)
        // .post('auths/emailVerify', data)
        .then(({ data }) => {
          notistack.success(data.message);
          resolve(data);
        })
        .catch(({ response }) => {
          notistack.error(response.data.message);
          reject(response);
        });
    });
  },
  profile(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update("auths", "profile_update", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          notistack.error(response.data.message);
          reject(response);
        });
    });
  },
  changePassword(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        // .post('auths/change_password', data)
        .post("Authentication/ChangePassword", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          notistack.error(response.data.message);
          reject(response);
        });
    });
  },
  OTPVarification(Flag, data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        // .post('auths/change_password', data)
        .post(`Authentication/OTPVerification?Flag=${Flag}`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          notistack.error(response.data.message);
          reject(response);
        });
    });
  },
  getToken() {
    return window.sessionStorage.getItem(ID_TOKEN_KEY);
  },
  saveToken(token) {
    window.sessionStorage.setItem(ID_TOKEN_KEY, token);
  },
  destroyToken() {
    window.sessionStorage.removeItem(ID_TOKEN_KEY);
  },
};
export default auth;
