const attribute = {
  name: "Name",
  first_name: "First name",
  last_name: "Last name",
  full_name: "Full name",
  role: "Role",
  email: "Email",
  closing_date: "Closing Date",
  password: "Password",
  confirm_password: "Confirm Password",
  phone: "Phone",
  country: "Country",
  city: "City",
  background: "Background",
  date: "Date",
  comments: "Comments",
  testimony: "Testimony",
  read_all: "Read all",
  age: "Age",
  address: "Address",
  category: "Category",
  siret_no: "SIRET Number",
  flat: "Flat",
  percentage: "Percentage",
  landmark: "Landmark",
  Vat: "Vat",
  marchant_firstName: "Marchant FirstName",
  marchant_lastName: "Marchant LastName",
  store_name_en: "Store Name En",
  store_name_fr: "Store Name Fr",

  id: "Id",
  identifier: "Identifier",
  page: "Page",
  container: "Container",
  text: "Text",
  images: "Images",
  avatar: "Avatar",
  icon: "Icon",
  map: "Map",
  buttons: "Buttons",
  form: "Form",
  minimum_date: "Minimum Date",
  maximum_date: "Maximum Date",
  select_date: "Select Date",
  selected_date: "Selected Date",
  select_code: "Select Country Code",
  title: "Title",
  view_count: "View Count",
  authore: "Authore",
  status: "Status",
  subject: "Subject",
  message: "Message",
  language: "Language",
  titleFr: "TitleFr",
  titleEn: "TitleEn",
  MessageEn: "MessageEn",
  MessageFr: "MessageFr",
  Customers: "Customers",
  Stores: "Stores",
  AllUsers: "All Users",
  minimum: "Minimum",
  maximum: "Maximum",
  actual_value: "Actual value",
  commissions: "Commissions",
  help_category_name_en: "Help Category Name En",
  help_category_name_fr: "Help Category Name Fr",
  help_category_english: "Help Category English",
  help_category_french: "Help Category French",
  image: "Image",
  action: "Action",
  description_en: "Description En",
  description_fr: "Description Fr",

  code: "Code",
  country_id: "Country Id",
  country_name: "Country Name",
  city_name_en: "City Name English",
  city_name_fr: "City Name French",
  zip_code: "Zipcode",
  city_id: "City Id",

  country_name_en: "Country Name English",
  country_name_fr: "Country Name French",
  country_code: "Country Code",

  mobile_number: "Mobile Number",
  zip_code_id: "ZipCodeId",

  order_id: "Order Id",
  customer_name: "Customer Name",
  // address:'',
  order_details: "Orders Details",
  contact_number: "Contact Number",
  store_name: "Store Name",
  total: "Total",
  buckets_details: "Buckets Details",
  order_status: "Orders Status",
  orders: "Ordres",

  marchant_first_name: "Marchant First Name",
  marchant_last_name: "Marchant Last Name",
  vat: "Vat",

  help_category: "Help category",

  total_quantity: "Total Quantity",
  payment_status: "Payment Status",
  paymode: "Paymode",
  picup_status_date: "Picup status date",
  pick_up_time: "Pick up time",
  flag:"Flag",
  promocode: "Promo Code",
  store_id: "Select Store",
  category_id: "Select Category",
  type: "Type",
  value: "Value",
  from_date: "From Date",
  to_date: "To Date",
  per: "Percentage Discount",
  flat: "Flat Discount",

  product_type_en: "Product Type English",
  product_type_fr: "Product Type French",
  product_description_in_en: "Product Description In English",
  product_description_in_fr: "Product Description In French",

  category_name_en: "Category Name English",
  category_name_fr: "Category Name French",
  category_image: "Category Image",

  category_type: "Category Type",
  no_of_orders: "Number of Orders",
  approve_status: "Approve Status",
  approve_store: "Approve Store",

  select: "Select",
  order_cost: "Order Cost",
  amount_to_settle: "Amount To Settle",
  order_date: "Order Date",

  store: "Store",
  date_start: "Start Date",
  date_end: "End date",

  help_description: "Help Description",

  // total_order:'Total Order',
  payment_mode: "Payment Mode",
  picked_up_time: "Picked Up Time",
  picked_up_date: "Picked Up Date",

  bucket_name: "Bucket Name",
  item_type: "Item Type",
  activation_date: "Activation Date",
  activation_from_time: "Activation From Time",
  Activation_to_time: "Activation To Time",
  withdrawal_date: "Withdrawal Date",
  withdrawal_from_time: "Withdrawal From Time",
  withdrawal_to_time: "Withdrawal To Time",
  bucket_amount: "Bucket Amount",
  bucket_discount_amount: "Bucket Discount Amount",
  bucket_quantity: "Bucket Quantity",
  sale_quantity: "Sale Quantity",

  upload_excel_file: "Upload Excel File",
  download_demo_file_from_here: "Download Demo File From here",
  download_demo_file: "Download Demo File",

  order_total: "Order Total",
  total_revenue: "Total Revenue",
  admin_commission: "Admin Commission",
  amount_to_pay: "Amount To Pay",
  search: "Search",
  pending: "Pending",
  settled: "Settled",
  to: "to",

  not_approve_status: "Not Approve Status",
  payment_settlement: "Payment Settlement",
  transaction_details: "Transaction Details",

  help_topic_title: "Help Topic Title",

  city_list: "City List",
  help_section_list: "Help Section List",
  Stores_list: "Stores List",
  order_list: "Order List",
  customer_list: "Customer List",
  help_section_category_list: "Help Section Category List",
  country_list: "Country List",
  zipcode_list: "Zipcode List",
  product_type_list: "Product Type List",
  category_list: "Category List",
  promocode_list: "Promocode List",

  email_verify: "Verified",
  verified: "Verified",
  not_verified: "Not Verified",

  delivered: "Delivered",

  this_status_is_not_approved: "This Status is not approved",
  this_status_is_approved: "This Status is approved",

  email_is_already_confirm: "Email is already Confirm",
  this_store_is_approved: "This Store is approved",

  store_logo_image: "StoreLogo Image",
  home_screen_image: "HomeScreenImage",

  customers_details: "Customer Details",

  store_logo: "StoreLogo",

  this_email_verified_previously: "This Email Verified Previously",
  this_email_verified_successfully: "This Email Verified Successfully",
  Reset_password_email_send_successfully:
    "Reset password email send successfully ",
  Resend_email_send_successfully: "Resend email send successfully",
  Something_went_wrong:"Something went wrong. Please try again.",
  store_details: "Store Details",
  city_name: "City Name",
  customer_id: "Customer Id",
  bucket_id: "Bucket Id",
  mobile_no: "Mobile Number",

  help_category_nameEn: "Help  Category Name English",
  help_category_nameFr: "Help  Category Name French",

  active_buckets: "Active Buckets",
  inactive_buckets: "Inactive Buckets",

  discount_amount: "Discount Amount",
  login_succesfully: "Login Succesfully",

  All: "All",
};
export default attribute;
