import axios from "../plugin/axios";

const customers = {
  list(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Customer/GetCustomers?Language=${data}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  create(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("Customer/CreateCustomer", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  get(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Customer/GetCustomerById/${data.id}?Language=${data.Language}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  resetPasswordsEmail() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Customer/ResetPasswordsEmail`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  sendNotification(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`Customer/SendNotifications` ,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  update(id, data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update("Customer/UpdateCustomer", id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .delete(`Customer/DeleteCustomer/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  VerifyCustomerEmail(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`Customer/VerifyCustomerEmail?Email=${data}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  UpdateCustomerStatus(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .update("Customer/UpdateCustomerStatus", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
export default customers;
