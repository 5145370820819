const error = {
    confirmation_password: 'The password confirmation does not match.',
    validators_password: 'The :attribute must be a valid.',
    404: '404 Not Found',
    405: '405 Method Not Allowed',
    500: '500 Internal Server Error',
    error_message: 'This page may have been moved or deleted. Be sure to check your spelling.',
    back_to_home: 'Back To Home',
    access_message: 'Only Admin can access this Page.Thank you..'
};
export default error;