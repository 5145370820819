const attribute = {
  // Leads
  name: "Nom",
  first_name: "Prénom",
  last_name: "Nom de famille",
  full_name: "Nom et prénom",
  role: "Rôle",
  email: "E-mail",
  closing_date: "Date de clôture",
  password: "Mot de passe",
  confirm_password: "Conformez le mot de passe",
  phone: "Téléphone",
  country: "De campagne",
  city: "Ville",
  background: "Arrière plan",
  date: "Date",
  flag:"Drapeau",
  comments: "commentaires",
  testimony: "Témoignage",
  read_all: "Lis tout",
  age: "Âge",
  address: "Adresse",
  category: "Catégorie",
  siret_no: "Numéro SIRET",

  country_code: "Code postal",
  landmark: "Repère",
  Vat: "T.V.A",
  marchant_firstName: "Prénom Marchant",
  marchant_lastName: "Nom Marchant",
  store_name_en: "Winkel naam En",
  store_name_fr: "Winkel naam Fr",
  titleFr: "TitreFr",
  titleEn: "TitreEn",
  MessageFr: "MessageFr",
  MessageEn: "MessageEn",
  Customers: "Clientèle",
  Stores: "Magasins",
  AllUsers: "Tous les utilisateurs",
  id: "Identifiant",
  identifier: "Identifiant",
  page: "Page",
  container: "Récipient",
  text: "Texte",
  images: "Images",
  avatar: "Avatar",
  icon: "Icône",
  map: "Carte",
  buttons: "Boutons",
  form: "Formulaire",
  minimum_date: "Date minimale",
  maximum_date: "Date maximale",
  select_date: "Sélectionner une date",
  selected_date: "Date sélectionnée",
  select_code: "Sélectionnez le code du pays",

  title: "Titre",
  en_title: "En Titre",
  fr_title: "Fr Titre",
  en_short_description: "En brève description",
  fr_short_description: "Fr brève description",
  en_long_description: "En longue description",
  fr_long_description: "Fr longue description",
  en_category: "En Catégorie",
  fr_category: "Fr Catégorie",
  view_count: "Nombre de vues",
  authore: "Auteur",
  en_authore: "En Auteur",
  fr_authore: "Fr Auteur",
  status: "Statut",
  subject: "Matière",
  message: "Message",
  language: "Langue",

  minimum: "Le minimum",
  maximum: "Maximum",
  actual_value: "Valeur actuelle",

  text_en: "Texte En",
  text_fr: "Texte Fr",
  commissions: "Commissions",
  help_category_name_en: "Nom de la catégorie daide Fr",
  help_category_name_fr: "Nom de la catégorie daide En",
  help_category_english: "Catégorie daide Anglaise",
  help_category_french: "Catégorie daide Français",
  image: "Image",
  action: "Action",
  description_en: "Description En",
  description_fr: "Description Fr",

  code: "Code",
  country_id: "Identifiant du pays",

  country_name_en: "Nom du pays Anglais",
  country_name_fr: "Nom du pays Français",

  country_name: "Nom du pays",
  city_name_en: "Nom de la ville anglais",
  city_name_fr: "Nom de la ville Français",

  zip_code: "Code postal",
  city_id: "Identifiant de la ville",

  mobile_number: "Numéro de portable",
  zip_code_id: "Identifiant du code postal",
  flat: "plat ",
  percentage: "Pourcentage",
  order_id: "Numéro de commande",
  customer_name: "Nom du client",
  order_details: "Détails des commandes",
  contact_number: "Numéro de contact",
  store_name: "Nom du magasin",
  total: "Totale",
  buckets_details: "Détails des seaux",
  order_status: "Statut des commandes",
  orders: "Orders",

  marchant_first_name: "Prénom marchand",
  marchant_last_name: "Nom de famille du marchand",
  vat: "T.V.A",

  help_category: "Catégorie d aide",

  total_quantity: "Quantité totale",
  payment_status: "Statut de paiement",
  paymode: "Mode de paiement",
  picup_status_date: "Date d état de la cueillette",
  pick_up_time: "Heure de ramassage",

  promocode: "Code promo",
  store_id: "Sélectionnez un magasin",
  category_id: "Choisir une catégorie",
  type: "Taper",
  value: "Valeur",
  from_date: "Partir de la date",
  to_date: "À ce jour",
  per: "Pourcentage de réduction",
  flat: "Remise forfaitaire",
  Reset_password_email_send_successfully:
    "Réinitialiser le mot de passe par e-mail envoyé avec succès",
  Resend_email_send_successfully: "Renvoyer l'e-mail avec succès",
  Something_went_wrong: "Quelque chose s'est mal passé. Veuillez réessayer.",
  product_type_en: "Type de produit Anglais",
  product_type_fr: "Type de produit Français",
  product_description_in_en: "Description du produit en anglais",
  product_description_in_fr: "Description du produit en français",

  category_name_en: "Nom de la catégorie Français",
  category_name_fr: "Nom de la catégorie Français",
  category_image: "Image de catégorie",

  category_type: "Type de catégorie",
  no_of_orders: "Nombre de commandes",
  approve_status: "Approuver le statut",
  approve_store: "Approuver le magasin",

  select: "Sélectionner",
  order_cost: "Coût de la commande",
  admin_commission: "Commission d administration",
  amount_to_settled: "Montant à régler",
  order_date: "Date de commande",

  store: "Magasin",
  date_start: "Date de début",
  date_end: "Date de fin",

  help_description: "Aide Description",

  // total_order:'Commande totale',
  payment_mode: "Mode de paiement",
  picked_up_time: "Temps ramassé",
  picked_up_date: "Date de ramassage",

  bucket_name: "Nom du compartiment",
  item_type: "Type d élément",
  activation_date: "Date d activation",
  activation_from_time: "Activation à partir de l heure",
  Activation_to_time: "Temps d activation",
  withdrawal_date: "Date de retrait",
  withdrawal_from_time: "Temps de retrait",
  withdrawal_to_time: "Délai de retrait",
  bucket_amount: "Montant de la catégorie",
  bucket_discount_amount: "Montant de remise de groupe",
  bucket_quantity: "Quantité de godet",
  sale_quantity: "Quantité de vente",

  upload_excel_file: "Téléchargez le fichier Excel",
  download_demo_file_from_here:
    "Télécharger le fichier de démonstration à partir d ici",
  download_demo_file: "Télécharger le fichier de démonstration",

  order_total: "Commande totale",
  total_revenue: "Revenu total",
  amount_to_pay: "Montant à payer",
  search: "Recherche",
  pending: "En attente",
  settled: "Installé",
  to: "pour",

  not_approve_status: "Statut non approuvé",
  payment_settlement: "Règlement de paiement",
  transaction_details: "détails de la transaction",

  city_list: "Liste des villes",
  help_section_list: "Liste des sections d aide",
  Stores_list: "Liste des magasins",
  order_list: "Liste de commandes",
  customer_list: "Liste de clients",
  help_section_category_list: "Liste des catégories de la section d aide",
  country_list: "Liste des pays",
  zipcode_list: "Liste des codes postaux",
  product_type_list: "Liste des types de produits",
  category_list: "Liste des catégories",
  promocode_list: "Liste des codes promotionnels",

  email_verify: "Vérifié",

  verified: "Vérifié",
  not_verified: "Non vérifié",
  delivered: "Livré",

  this_status_is_not_approved: "Ce statut n est pas approuvé",
  this_status_is_approved: "Ce statut est approuvé",

  email_is_already_confirm: "L e-mail est déjà confirmé",
  this_store_is_approved: "Ce magasin est approuvé",

  store_logo_image: "StoreLogo Image",
  home_screen_image: "AccueilÉcranImage",

  customers_details: "Détails du client",

  store_logo: "StoreLogo",

  this_email_verified_previously: "Cet e-mail a déjà été vérifié",
  this_email_verified_successfully: "Cet e-mail a été vérifié avec succès",

  store_details: "Détails du magasin",
  city_name: "Nom de Ville",
  customer_id: "N ° de client",
  bucket_id: "Identifiant du compartiment",
  mobile_no: "Numéro de portable",

  help_category_nameEn: "Nom de la catégorie d aide",
  help_category_nameFr: "Nom de la catégorie d aide",

  active_buckets: "Compartiments actifs",
  inactive_buckets: "Compartiments inactifs",

  discount_amount: "Montant de la remise",
  login_succesfully: "Connexion réussie",

  All: "Toute",
};
export default attribute;
