import axios from '../plugin/axios';

const categories = {
    list(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Category/GetCategories?Language=${data}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('Category/SaveCategory', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Category/GetCategoryById/${data.id}?Language=${data.Language}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Category/UpdateCategory', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    UpdateCategoryStatus(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Category/UpdateCategoryStatus', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Category/DeleteCategory', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};
export default categories;
