import React, { createContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth } from '../models';

export const AppContext = createContext();

export const ApplicationDataProvider = ({ children }) => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [user, setUser] = useState();

    const getAllData = () => {
        getUser();
    }
    const getUser = () => {
        if (auth.getToken()) {
            auth.getUser(i18n.language === "enUS" ? "EN" : "FR").then((data) => {
                if (data.Email) {
                    setUser(data);
                } else {
                    navigate('/verify');
                }
            }).catch(() => {
                navigate('/login');
            });
        } else {
            navigate('/login');
        }
    };
    const providerState = {
        user,
        getUser: getUser,
        getAllData: getAllData,
    };
    return (
        <AppContext.Provider value={providerState}>
            {children}
        </AppContext.Provider>
    );
};

export const AppDataConsumer = AppContext.Consumer;
export default AppContext;