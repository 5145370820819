const menu = {
  home: "Domicile",
  dashboard: "Tableau de bord",
  masters: "Maîtrise",
  categories: "Catégories",
  stores: "Magasins",
  buckets: "Panier",
  promocodes: "Code de promo",
  act2win: "Act2Win",
  customer_management: "Gestion de la clientèle",
  order_management: "La gestion des commandes",
  store_management: "Gestion de magasin",
  payment_settlement: "Règlement de paiement",
  help_section: "Section d aide",
  commission: "Commission",
  product_type: "type de produit",
  upload_excel: "Télécharger Excel",
  zipcode: "Code postal",
  help_category: "Catégorie daide",
  notification:"Notification",
  pages: "Pages",
  update_page: "Mettre à jour la page",
  Send:"Envoyer",
  customers: "Clientes",
  new_customer: "Nouveau client",
  update_customer: "Mettre à jour le client",
  customer_details: "Détails du client",
  resend_verification:"Renvoyer Vérification",
  Reset_Password:"Réinitialiser le mot de passe",
  orders: "Ordres",
  new_order: "Nouvel ordre",
  update_order: "Mise à jour de la commande",
  order_details: "détails de la commande",

  stores: "Jaloezieën",
  new_store: "Nieuwe winkel",
  update_store: "Winkel bijwerken",
  // store_details: 'Winkeldetails',
  select_store: "Sélectionnez un magasin",

  search: "Recherche..",
  messages: "Messages",
  notifications: "Avis",
  profile: "Profil",
  change_password: "Changer le mot de passe",
  logout: "Se déconnecter",

  create: "Créer",
  edit: "Modifier",
  delete: "Supprimer",
  view: "Voir",
  convert: "Convertir",
  send_email: "Envoyer un e-mail",
  bucketsView: "Emmers bekijken",

  contact: "Contact",
  newsLetter: "Lettre d`information",
  events: "Événements",
  engagement: "Engagement",
  donation: "Don",

  update_categories: "Mettre à jour les catégories",
  new_categories: "Nouvelles catégories",
  cancel: "Annuler",
  save: "Sauvegarder",
  update_product_type: "Mettre à jour le type de produit",
  new_product_type: "Nouveau type de produit",

  update_upload_excel: "Mettre à jour télécharger excel",
  upload_excel: "Télécharger Excel",

  update_country: "Mettre à jour le pays",
  new_country: "Nouveau pays",
  country: "Pays",

  update_city: "Mettre à jour la ville",
  new_city: "Nouvelle ville",
  city: "Ville",
  update_zip_code: "Mettre à jour le code postal",
  new_zip_code: "Nouveau code postal",
  zip_code: "Code postal",

  update_help_categories: "Mettre à jour les catégories d aide",
  new_help_categories: "Nouvelles catégories d aide ",

  verify_email: "Vérifier les courriels",
  update_store: "Mettre à jour le magasin",
  new_store: "Nouveau magasin",

  update_help_section: "Mettre à jour la section d aide",
  new_help_section: "Nouvelle section d aide",

  upload_excel_for_country_city_and_zipcode:
    "Téléchargez Excel pour le pays, la ville et le code postal",

  new_promocode: "Nouveau code promotionnel",
  update_promocode: "Mettre à jour le code promotionnel",
  new_promocode: "Nouveau code promotionnel",

  promocode_details: "Détails du code promotionnel",
  verify_promocode: "Vérifier le code promotionnel",

  update_commissions: "Commission de mise à jour",
  orders_details: "Détails des commandes",

  bucket_details: "Détails du godet",
  store_details: "Détails du magasin",

  // show:'Stores',
  // bucket:'Bucket',
  PaymentSettlement: "Règlement de paiement",

  bucket_list: "Liste de seaux",
};
export default menu;
